import { createBrowserRouter } from 'react-router-dom';
import { ROUTE_PATH } from '../constants/route';
import Landing from '../pages/Landing';

const routes = createBrowserRouter([
  {
    path: ROUTE_PATH.LOGIN,
    element: <><Landing /></>,
  },
]);

export default routes;
