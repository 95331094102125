import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import './button.scss';

export const PrimaryButton = styled(Button)({
  background: '#EA9D2D0D',
  backgroundColor: '#EA9D2D0D',
  borderRadius: '6px',
  // height: '45px',
  textTransform: 'none',
  width: '100%',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '22px',
  fontFamily: 'Inter, sans-serif !important',
  color: '#101010',
  boxShadow: 'none',
  border: 'none',
  whiteSpace: 'nowrap',
  '&:hover': {
    // backgroundColor: '#ff970021',
    backgroundColor: '#ff97002b',
  },
});

export const SecondaryButton = styled(Button)({
  background: '#FFFFFF',
  borderRadius: '6px',
  height: '45px',
  textTransform: 'none',
  width: '100%',
  fontWeight: '500',
  border: '1px solid #CDA14B',
  fontSize: '16px',
  lineHeight: '22px',
  fontFamily: 'Inter, sans-serif !important',
  color: '#CDA14B',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
});

export const TrinaryButton = styled(Button)({
  background: '#999999',
  backgroundColor: '#999999',
  borderRadius: '6px',
  height: '45px',
  textTransform: 'none',
  width: '100%',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '22px',
  color: '#FFFFFF',
  fontFamily: 'Inter, sans-serif !important',
  boxShadow: 'none',
  border: 'none',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: '#999999',
  },
});
