const ROUTE_PATH = {
  PAGE404: '*',
  LOGIN: '/',
  CUSTOMER: '/customer',
};

const API_PATH = {
  USER: '/products',
  LOGIN: '/admin-login',
};

export { ROUTE_PATH, API_PATH };
