import React, { useRef, useState } from 'react';
import instagram from "../../assets/svg/instagram.svg"
import logo from "../../assets/svg/logo.svg"
import facebook from "../../assets/svg/facebook.svg"
import tiktok from "../../assets/svg/tiktok.svg"
import { PrimaryButton } from "../../components/Button";
import final1landing from "../../assets/video/final1landing.mp4";
import outstorylandingfinal from "../../assets/video/Outstorylandingfinal.mp4";
import './landing.scss';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import CustomAccordion from '../../components/Accordion';
import cross from "../../assets/svg/cross.svg";
import appStore from "../../assets/svg/appStore.svg";
import "../../styles/_global.scss"
import Modal from '../../components/Modal';

const AccordianData = [
  {
    title: 'What is Rememery? 🧡',
    description: `Rememery is a lifelogging app designed to help families preserve cherished memories and facilitate intergenerational connections. Beyond its current features, Rememery is built with a forward-thinking approach, anticipating the future growth of technology. We're constantly innovating to ensure our platform remains at the forefront of digital memory preservation and storytelling. `,
  },
  {
    title: 'Why was Rememery created? 🤔',
    description: `Rememery was designed for those seeking more than what mainstream social media offers. It's a place for living in the moment, reminiscing on the past, and sharing messages into the future. We focus on authentic, meaningful experiences, moving away from the fleeting and superficial nature of typical social platforms. Rememery is about creating a lasting legacy, ensuring that the stories and memories that shape us are preserved for generations to come.`,
  },
  {
    title: 'Is Rememery free? 💰',
    description: `Rememery is completely free to use! We believe that preserving and sharing family memories should be accessible to everyone, which is why we offer our core lifelogging services at no cost.`,
  },
  {
    title: 'How do I join? ✋',
    description: `Download the Rememery app on the IOS store and create your lifelogging account using your phone number.`,
  },
  {
    title: 'How can I preserve my parents/grandparents stories? 👨‍👧‍👦',
    description: `You can easily send storytelling requests or ask for the story behind a photo or video. Your loved ones can respond via a link on their mobile device without needing to download the app. It's a simple way to help build and preserve their life stories!`,
  },
  {
    title: 'What are the privacy settings? 🔐',
    description: `Rememery is a private social lifelogging app where you control who sees your stories. Connect with friends or family through mutual acceptance and choose who to share your life moments with – friends, family, or both!`,
  },
  {
    title: 'How do time capsules work? ⏳',
    description: `Time capsules are perfect for capturing life's big events, everyday moments, and everything in between. You can even set up categories for future events, like welcoming a child. Friends and family can send their time capsules to these categories, ready for you to unlock and enjoy when the special day arrives. It's your personal treasure trove of memories, waiting to be revealed at just the right moment!`,
  },
  {
    title: 'Does Rememery have a feature to digitize physical photos? 🎆',
    description: `Rememery includes a convenient Photo Scanner feature, allowing you to easily digitize and preserve your cherished physical photographs to share stories about!`,
  },
]

const Landing = () => {
  const videoRef = useRef(null);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [showFaq, setShowFaq] = useState(false);
  const [openOurStoryModal, setOpenOurStoryModal] = useState(false);

  const playVideo = (videoRef, ismuted = false) => {
    if (videoRef.current) {
      if (ismuted) {
        videoRef.current.muted = true;
        setIsVideoMuted(true);
      } else {
        if (videoRef.current.muted) {
          videoRef.current.muted = false;
          setIsVideoMuted(false);
        } else {
          videoRef.current.muted = true;
          setIsVideoMuted(true);
        }
      }
    }
  };

  const renderFaq = () => {
    return (
      <div className={`faq-page-wrap ${showFaq ? 'faq-tansition' : ''}`}>
        <div className="page-header-wrap">
          <img src={cross} alt="" className='curser-pointer crossIcon'
            onClick={() => setShowFaq(false)}
          />
        </div>
        <div className="page-content-wrap">
          <div className="heading">FAQs</div>
          <div className="content-wrap">
            {
              AccordianData.map((item) => {
                return (
                  <CustomAccordion title={item?.title} description={item?.description} />
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }

  const handleOurStory = () => {
    setOpenOurStoryModal(true);
    playVideo(videoRef, true)
  }

  const renderHeader = () => {
    return (
      <div className="header-wrap">
        <div>
          <PrimaryButton
            sx={{
              padding: '12px 24px',
              borderRadius: '16px',
              backdropFilter: 'blur(11.5px)',
              fontWeight: 600,
              fontSize: '18px',
              '@media screen and (max-width: 480px)': {
                fontSize: '14px',
              },
            }}
            onClick={() => setShowFaq(true)}
          >💭 FAQs</PrimaryButton>
        </div>
        <div>
          <PrimaryButton
            sx={{
              padding: '12px 24px',
              borderRadius: '16px',
              backdropFilter: 'blur(11.5px)',
              fontWeight: 600,
              fontSize: '18px',
              '@media screen and (max-width: 480px)': {
                fontSize: '14px',
              },
            }}
            onClick={() => handleOurStory()}
          >😄 Our story</PrimaryButton>
        </div>
      </div>
    )
  }

  const renderOurStoryContent = () => {
    return (
      <>
        <video controls className='ourStoryVideo' autoPlay>
          <source src={outstorylandingfinal} type="video/mp4" />
        </video>
      </>

    )
  }

  const handleContactClick = () => {
    const emailAddress = 'support@rememery.com';
    const mailtoLink = `mailto:${emailAddress}`;
    window.location.href = mailtoLink;
  }

  const renderFooter = () => {
    return (
      <div className="footer-wrap">
        <div>
          <a href="https://www.instagram.com/rememeryapp/" target='_blank' rel="noreferrer" >
            <img src={instagram} alt="" className='curser-pointer hoverEffect' />
          </a>
          <a href="https://www.facebook.com/rememeryapp/" target='_blank' rel="noreferrer" >
            <img src={facebook} alt="" className='curser-pointer hoverEffect' />
          </a>
          <a href="https://www.tiktok.com/@rememeryapp?_t=8jMNSYVwcHO&_r=1" target='_blank' rel="noreferrer" >
            <img src={tiktok} alt="" className='curser-pointer hoverEffect' />
          </a>
        </div>
        <div>
          <PrimaryButton
            sx={{
              padding: '12px 24px',
              borderRadius: '16px',
              backdropFilter: 'blur(11.5px)',
              fontWeight: 600,
              fontSize: '18px',
              '@media screen and (max-width: 480px)': {
                fontSize: '14px',
              },
            }}
            onClick={() => handleContactClick()}
          >Contact</PrimaryButton>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="landing-page-wrap">
        <div className={`top-section-wrap ${showFaq ? 'hide-transition' : ''}`}>
          <div className="left-section-wrap">
            <div>
              {renderHeader()}
              <div className="content-wrap">
                <div>
                  <img src={logo} alt="Rememery" className='logoIcon' />
                </div>
                <div>
                  <div className="heading-1">
                    A haven for family memories, ensuring your favourite stories are captured and remembered forever
                  </div>
                  <div className="heading-1">
                    Inspired by innovative memory preservation,
                    Rememery is designed for families to
                  </div>
                </div>
                <div>
                  <div className="points">
                    Live in the {' '}
                    <span className='highlightedPoint'>
                      present
                    </span>
                  </div>
                  <div className="points">Reminisce on the {' '}
                    <span className='highlightedPoint'>
                      past
                    </span>
                  </div>
                  <div className="points">Share into the {' '}
                    <span className='highlightedPoint'>
                      future
                    </span>
                  </div>
                </div>
                <div className="heading-1">
                  Begin your lifelogging journey now, and capture the
                  beautiful stories of your loved ones for generations to come.
                </div>
              </div>
            </div>
            {renderFooter()}
          </div>
          <div className="right-section-wrap">
            {renderHeader()}
            <div className='video-wrap'>
              <video ref={videoRef} className="video" controls={false} autoPlay loop muted playsInline>
                <source src={final1landing} type="video/mp4" />
              </video>
              <span className='muteIcon' onClick={() => playVideo(videoRef)}>{isVideoMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}</span>
              <a href="https://apps.apple.com/in/app/rememery/id1661505441" target='_blank' rel="noreferrer">
                <img src={appStore} alt="" className='appStoreIcon' />
              </a>
            </div>
          </div>
        </div>
        {renderFaq()}
      </div>
      <Modal
        openDialog={openOurStoryModal}
        closeDialog={() => setOpenOurStoryModal(false)}
        dialogCss='ourStoryModal'
        dialogContent={renderOurStoryContent()}
      />
    </>
  )
};

export default Landing