import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import rightArrow from "../../assets/svg/rightArrow.svg"
import "./accordion.scss"

export default function CustomAccordion(props) {
    const {title = '', description = ''}  = props

  return (
    <div className="AccordianWrap">
      <Accordion
      >
        <AccordionSummary
          expandIcon={<img alt="Right Arrow Icon" src={rightArrow} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {description}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
