/* eslint-disable consistent-return */
import { createAsyncThunk } from '@reduxjs/toolkit';
import create from '../../../services/api';
import { API_PATH } from '../../../constants/route';
import { updateLoading } from '../loader';

const fetchLogout = createAsyncThunk('authentication/logout', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.LOGOUT, data);
    dispatch(updateLoading(false));
    localStorage.clear();
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchLogin = createAsyncThunk('authentication/login', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.LOGIN, data);
    dispatch(updateLoading(false));
    const { data: { data: { token }, status } = {} } = response;
    if (status) {
      localStorage.setItem('auth', token);
    }
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchUser = createAsyncThunk('authentication/user', async (data, { dispatch, getState }) => {
  const API = create({});
  console.log('getState', getState);
  try {
    dispatch(updateLoading(true));
    const response = await API.get(API_PATH.USER, data);
    dispatch(updateLoading(false));
    return response.data;
  } catch (err) {
    return err;
  }
});

export {
  fetchLogin,
  fetchLogout,
  fetchUser,
};
